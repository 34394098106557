import { combineReducers } from '@reduxjs/toolkit';
import signerReducer from './ducks/Signer';
import userReducer from './ducks/User';
import cobapReducer from './ducks/Cobap';
import dashboardReducer from './ducks/Dashboard';
import groupsReducer from './ducks/Groups';
import ProposalStatusReducer from './ducks/Proposal';
import reasonReducer from './ducks/Reason';
import CustomerReducer from './ducks/Customer';
import reportsReducer from './ducks/Reports';
import reportsCobapReducer from './ducks/Reports/cobap';
import SignaturesStatusReducer from './ducks/Signature';
import FilesStatusReducer from './ducks/Files';
import PartnerStatusReducer from './ducks/Partner';
import CompanyStatusReducer from './ducks/Company';
import associationsReducer from './ducks/Association';
import federationsReducer from './ducks/Federation';
import paramterizationsReducer from './ducks/Parameterizations';
import settingsReducer from './ducks/Settings';

const rootReducer = combineReducers({
  cobap: cobapReducer,
  dashboard: dashboardReducer,
  groups: groupsReducer,
  proposal: ProposalStatusReducer,
  customer: CustomerReducer,
  files: FilesStatusReducer,
  partner: PartnerStatusReducer,
  company: CompanyStatusReducer,
  reports: combineReducers({ general: reportsReducer, cobap: reportsCobapReducer }),
  signatures: SignaturesStatusReducer,
  signer: signerReducer,
  user: userReducer,
  association: associationsReducer,
  parameterization: paramterizationsReducer,
  federation: federationsReducer,
  reason: reasonReducer,
  settings: settingsReducer,
});

export default rootReducer;
