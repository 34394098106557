/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { signaturesStatus, clientReportsResponse, FilterDate } from 'interfaces/SignaturesStatus';
import { errorRedux } from 'interfaces/redux';
import { auditSignatureResponse } from 'interfaces/Signature';
import { getAuditSignature, getSignaturesStatus } from './service';

export const signaturesStatusList = createAsyncThunk<signaturesStatus, FilterDate, { rejectValue: errorRedux }>(
  'signatures/entities/list',
  async (querySignatureDate, { rejectWithValue }) => {
    try {
      const data = await getSignaturesStatus(querySignatureDate);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const auditSignature = createAsyncThunk<auditSignatureResponse, string, { rejectValue: errorRedux }>(
  'signature/confia',
  async (identifier, { rejectWithValue }) => {
    try {
      const data = await getAuditSignature(identifier);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

interface ISignatures extends errorRedux {
  signaturesStatusList: signaturesStatus;
  clientReportsCSV: clientReportsResponse;
}

const initialState: ISignatures = {
  status: 'idle',
  message: '',
  type: '',
  signaturesStatusList: {
    status: '',
    message: '',
    document: [],
  },
  clientReportsCSV: {
    status: '',
    message: '',
    document: [],
  },
};

export const SignaturesStatusReducer = createSlice({
  name: 'SignaturesStatus',
  initialState,
  reducers: {
    clearSignaturesStatus: (state) => {
      state.status = 'idle';
      state.message = '';
      state.type = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signaturesStatusList.pending, (state, action) => {
        state.status = 'loading';
        state.type = action.type;
      })
      .addCase(signaturesStatusList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.type = action.type;
        state.message = action.payload.message;
        state.signaturesStatusList = action.payload;
      })
      .addCase(signaturesStatusList.rejected, (state, action) => {
        state.status = 'failed';
        state.type = action.type;
        state.message = action.payload?.message || '';
      });
  },
});
export const { clearSignaturesStatus } = SignaturesStatusReducer.actions;
export default SignaturesStatusReducer.reducer;
