/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, lazy } from 'react';

import SuspenseLoader from 'components/SuspenseLoader';

const Loader = (Component: any) => {
  return (props: any) => {
    return (
      <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
      </Suspense>
    );
  };
};

const Home = Loader(
  lazy(() => {
    return import('pages/Home');
  }),
);

const Maintenance = Loader(
  lazy(() => {
    return import('pages/Maintenance');
  }),
);

const Recovery = Loader(
  lazy(() => {
    return import('pages/Recovery');
  }),
);

const Signin = Loader(
  lazy(() => {
    return import('pages/Signin');
  }),
);

const Layout = Loader(
  lazy(() => {
    return import('components/Layout');
  }),
);

const ListCobapAssociation = Loader(
  lazy(() => {
    return import('pages/Entities/Association/ListView');
  }),
);

const FormAssociationCobap = Loader(
  lazy(() => {
    return import('pages/Entities/Association/FormView');
  }),
);
const NewAssociationCobap = Loader(
  lazy(() => {
    return import('pages/Entities/Association/NewAssociation');
  }),
);

const ListCobapFederation = Loader(
  lazy(() => {
    return import('pages/Entities/Federation/ListView');
  }),
);

const FormFederationCobap = Loader(
  lazy(() => {
    return import('pages/Entities/Federation/FormView');
  }),
);
const NewFederationCobap = Loader(
  lazy(() => {
    return import('pages/Entities/Federation/NewFederation');
  }),
);

const ListProposalStatus = Loader(
  lazy(() => {
    return import('pages/Proposal/ListView');
  }),
);
const CustomerList = Loader(
  lazy(() => {
    return import('pages/Customer/ListView');
  }),
);

const FormCustomerStatus = Loader(
  lazy(() => {
    return import('pages/Customer/FormView');
  }),
);

const ListUsers = Loader(
  lazy(() => {
    return import('pages/UserManagementSettigns/Users/ListView');
  }),
);
const NewUser = Loader(
  lazy(() => {
    return import('pages/UserManagementSettigns/Users/NewUser');
  }),
);

const FormUser = Loader(
  lazy(() => {
    return import('pages/UserManagementSettigns/Users/FormView');
  }),
);
const ListPartners = Loader(
  lazy(() => {
    return import('pages/UserManagementSettigns/Partner/ListView');
  }),
);
const FormPartners = Loader(
  lazy(() => {
    return import('pages/UserManagementSettigns/Partner/FormView');
  }),
);

const NewPartners = Loader(
  lazy(() => {
    return import('pages/UserManagementSettigns/Partner/NewPartner');
  }),
);

const ListViewCompany = Loader(
  lazy(() => {
    return import('pages/UserManagementSettigns/Company/ListViewCompany');
  }),
);

const FormCompanies = Loader(
  lazy(() => {
    return import('pages/UserManagementSettigns/Company/FormViewCompany');
  }),
);

const NewCompany = Loader(
  lazy(() => {
    return import('pages/UserManagementSettigns/Company/NewCompany');
  }),
);

const GroupSettings = Loader(
  lazy(() => {
    return import('pages/UserManagementSettigns/Groups/ListView');
  }),
);

const FormGroup = Loader(
  lazy(() => {
    return import('pages/UserManagementSettigns/Groups/FormView');
  }),
);

const FormProposalStatus = Loader(
  lazy(() => {
    return import('pages/Proposal/FormView');
  }),
);

const ReportsCobap = Loader(
  lazy(() => {
    return import('pages/Entities/Reports');
  }),
);

const Dashboard = Loader(
  lazy(() => {
    return import('pages/Dashboard');
  }),
);

const PendingSubscriptions = Loader(
  lazy(() => {
    return import('pages/Entities/PendingSubscriptions');
  }),
);

const SendNotification = Loader(
  lazy(() => {
    return import('pages/Notification/SendNotification');
  }),
);

interface IRoutes {
  path: string;
  title: string;
  component: JSX.Element;
  isPrivate: boolean;
}

const routes: IRoutes[] = [
  {
    path: '/',
    title: 'Home',
    component: <Home />,
    isPrivate: true,
  },
  {
    path: '/maintenance',
    title: 'Maintenance',
    component: <Maintenance />,
    isPrivate: false,
  },
  {
    path: '/signin',
    title: 'Signin',
    component: <Signin />,
    isPrivate: false,
  },
  {
    path: '/recoverypassword/:base64',
    title: 'Recovery',
    component: <Recovery />,
    isPrivate: false,
  },
  {
    path: '/*',
    title: 'Layout',
    component: <Layout />,
    isPrivate: false,
  },
  {
    path: '/entities/list/association',
    title: 'List Cobap Association',
    component: <ListCobapAssociation />,
    isPrivate: true,
  },
  {
    path: '/entities/edit/association/:id',
    title: 'List Cobap Association',
    component: <FormAssociationCobap />,
    isPrivate: true,
  },
  {
    path: '/entities/new/association',
    title: 'New Cobap Association',
    component: <NewAssociationCobap />,
    isPrivate: true,
  },
  {
    path: '/entities/list/federation',
    title: 'List Cobap Federation',
    component: <ListCobapFederation />,
    isPrivate: true,
  },
  {
    path: '/entities/edit/federation/:id',
    title: 'List Cobap Federation',
    component: <FormFederationCobap />,
    isPrivate: true,
  },
  {
    path: '/entities/new/federation',
    title: 'New Cobap Federation',
    component: <NewFederationCobap />,
    isPrivate: true,
  },
  {
    path: '/entities/PendingSubscriptions',
    title: 'Assinaturas pendentes',
    component: <PendingSubscriptions />,
    isPrivate: true,
  },
  {
    path: '/proposal/list',
    title: 'Reports',
    component: <ListProposalStatus />,
    isPrivate: true,
  },
  {
    path: '/customer/list',
    title: 'Clients',
    component: <CustomerList />,
    isPrivate: true,
  },
  {
    path: '/customer/:id',
    title: 'Cliente',
    component: <FormCustomerStatus />,
    isPrivate: true,
  },
  {
    path: '/proposal/:id',
    title: 'Reports',
    component: <FormProposalStatus />,
    isPrivate: true,
  },
  {
    path: '/entities/reports',
    title: 'Relatório Cobap',
    component: <ReportsCobap />,
    isPrivate: true,
  },
  {
    path: '/dashboard',
    title: 'dashboard',
    component: <Dashboard />,
    isPrivate: true,
  },
  {
    path: '/userSettings/users',
    title: 'Users',
    component: <ListUsers />,
    isPrivate: true,
  },
  {
    path: '/userSettings/users/newUsers',
    title: 'Users',
    component: <NewUser />,
    isPrivate: true,
  },
  {
    path: '/userSettings/group',
    title: 'Users',
    component: <GroupSettings />,
    isPrivate: true,
  },
  {
    path: '/userSettings/group/:id',
    title: 'Users',
    component: <FormGroup />,
    isPrivate: true,
  },
  {
    path: '/userSettings/partner',
    title: 'Partner',
    component: <ListPartners />,
    isPrivate: true,
  },
  {
    path: '/userSettings/partner/:id',
    title: 'Partner',
    component: <FormPartners />,
    isPrivate: true,
  },
  {
    path: '/userSettings/partner/newPartner',
    title: 'Nova Partner',
    component: <NewPartners />,
    isPrivate: true,
  },
  {
    path: '/userSettings/company/:id',
    title: 'Company',
    component: <FormCompanies />,
    isPrivate: true,
  },
  {
    path: '/userSettings/company',
    title: 'Empresa',
    component: <ListViewCompany />,
    isPrivate: true,
  },
  {
    path: '/userSettings/company/newCompany',
    title: 'Nova Empresa',
    component: <NewCompany />,
    isPrivate: true,
  },
  {
    path: '/userSettings/users/:id',
    title: 'Users',
    component: <FormUser />,
    isPrivate: true,
  },
  {
    path: '/notifications/send',
    title: 'Enviar notificação',
    component: <SendNotification />,
    isPrivate: true,
  },
];

export default routes;
