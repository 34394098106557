/* eslint-disable object-curly-newline, camelcase, import/prefer-default-export */

import api from 'services';
import { resendContract, signer, signerResponse } from 'interfaces/Signer';
import { baseResponse } from 'interfaces/BaseResponse';

const path = '/signer';

export const signinPost = async ({ email, password, aplicacao }: signer): Promise<signerResponse> => {
  const response = await api.post<signerResponse>('/signin', { email, password, aplicacao });
  api.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
  return response.data;
};

export const postLogout = async (): Promise<void> => {
  await api.post('/users/logout');
  api.defaults.headers.common.Authorization = '';
};
export const patchProposalResendContract = async (resendContractParams: resendContract): Promise<baseResponse> => {
  const response = await api.patch<baseResponse>(`${path}/resend`, resendContractParams);
  return response.data;
};

export const patchProposalResendNotification = async ({
  identifier,
}: {
  identifier: string;
}): Promise<baseResponse> => {
  const response = await api.patch<baseResponse>(`${path}/resendNotification`, { identifier });
  return response.data;
};
