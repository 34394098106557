import {
  Article,
  Business,
  Group,
  Groups,
  BarChart,
  ManageAccounts,
  Pending,
  Apartment,
  Person,
} from '@mui/icons-material';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';

type IconType = OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
  muiName: string;
};

interface INested {
  title: string;
  label?: string;
  href: string;
}

export interface IMenu {
  title: string;
  label?: string;
  href?: string;
  isVisible: boolean;
  isNested?: boolean;
  nesteds?: INested[];
}

export interface IItem {
  title: string;
  label?: string;
  href?: string;
  icon: IconType;
  isVisible: boolean;
  anchorEl?: null;
  menus?: IMenu[];
}

export interface IPartner {
  title: string;
  label?: string;
  href?: string;
  icon: IconType;
  isVisible: boolean;
  open: boolean;
  itens: IItem[];
}

interface ISubsection {
  partners: IPartner[];
}

export interface ISections {
  subheader: string;
  section: string;
  label?: string;
  open: boolean;
  isVisible: boolean;
  href?: string;
  icon?: IconType;
  subsections?: ISubsection[];
}

const sections: ISections[] = [
  // {
  //   subheader: 'Dashboard',
  //   label: 'Dashboard',
  //   section: 'Dashboard',
  //   href: '/dashboard',
  //   open: true,
  //   icon: Dashboard,
  //   isVisible: false,
  // },
  {
    subheader: 'Propostas',
    section: 'Propostas',
    label: 'Propostas',
    href: '/proposal/list',
    open: true,
    icon: Article,
    isVisible: false,
  },
  {
    subheader: 'Cliente',
    section: 'Clientes',
    label: 'Clientes',
    href: '/customer/list',
    open: true,
    icon: Person,
    isVisible: true,
  },
  {
    subheader: 'Entidades',
    label: 'Entidades',
    section: 'Entidades',
    open: true,
    isVisible: false,
    subsections: [
      {
        partners: [
          {
            title: 'Assinaturas Pendentes',
            label: 'Assinaturas Pendentes',
            href: '/entities/PendingSubscriptions',
            open: false,
            icon: Pending,
            isVisible: false,
            itens: [],
          },
          {
            title: 'Associações',
            label: 'Associações',
            href: '/entities/list/association',
            icon: Group,
            open: false,
            isVisible: false,
            itens: [],
          },
          {
            title: 'Federações',
            label: 'Federações',
            href: '/entities/list/federation',
            icon: Groups,
            open: false,
            isVisible: false,
            itens: [],
          },
          {
            title: 'Relatórios',
            label: 'Relatórios',
            href: '/entities/reports',
            icon: BarChart,
            open: false,
            isVisible: false,
            itens: [],
          },
        ],
      },
    ],
  },
  {
    subheader: 'Gestão de Usuário',
    label: 'Gestão de Usuário',
    section: 'Gestão de Usuário',
    open: true,
    isVisible: false,
    subsections: [
      {
        partners: [
          {
            title: 'Grupos',
            label: 'Grupos',
            href: '/userSettings/group',
            icon: Groups,
            open: false,
            isVisible: true,
            itens: [],
          },
          {
            title: 'Empresas',
            label: 'Empresas',
            href: '/userSettings/company',
            icon: Business,
            open: false,
            isVisible: false,
            itens: [],
          },
          {
            title: 'Parceiros',
            label: 'Parceiros',
            href: '/userSettings/partner',
            icon: Apartment,
            open: false,
            isVisible: false,
            itens: [],
          },
          {
            title: 'Usuarios',
            label: 'Usuário',
            href: '/userSettings/users',
            icon: ManageAccounts,
            open: false,
            isVisible: false,
            itens: [],
          },
        ],
      },
    ],
  },
  {
    subheader: 'Notificações',
    label: 'Notificações',
    section: 'Notificações',
    open: true,
    isVisible: false,
    subsections: [
      {
        partners: [
          {
            title: 'Enviar notificação',
            label: 'Enviar notificação',
            icon: NotificationAddIcon,
            href: '/notifications/send',
            isVisible: false,
            open: false,
            itens: [],
          },
        ],
      },
    ],
  },
];

export default sections;
