/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { baseResponse } from 'interfaces/BaseResponse';
import { payloadSelfValidation, responseFiles } from 'interfaces/Files';
import { errorRedux } from 'interfaces/redux';

import { paramsQueryString } from 'utils/objects';
import { getFiles, patchSelfValidation, postFiles } from './service';

export const allSendFiles = createAsyncThunk<baseResponse, FormData, { rejectValue: errorRedux }>(
  'files/',
  async (searchAdvanced, { rejectWithValue }) => {
    try {
      const data: any = await postFiles(searchAdvanced);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const listFiles = createAsyncThunk<responseFiles, paramsQueryString, { rejectValue: errorRedux }>(
  'files/listFiles',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getFiles(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const updateSelfValidation = createAsyncThunk<baseResponse, payloadSelfValidation, { rejectValue: errorRedux }>(
  'files/selfValidation',
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await patchSelfValidation(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

interface IFiles extends errorRedux {
  sendFiles: baseResponse;
  selfValidarion: baseResponse;
}

const initialState: IFiles = {
  status: 'idle',
  message: '',
  sendFiles: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  selfValidarion: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
};

export const FilesStatusReducer = createSlice({
  name: 'FilesStatus',
  initialState,
  reducers: {
    clearFilesStatus: (state) => {
      state.status = 'idle';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allSendFiles.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(allSendFiles.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
      })
      .addCase(allSendFiles.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(updateSelfValidation.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateSelfValidation.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
      })
      .addCase(updateSelfValidation.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      });
  },
});
export const { clearFilesStatus } = FilesStatusReducer.actions;
export default FilesStatusReducer.reducer;
